import "./FullName.scss"

const FullName = () => {
    return (
        <p className="full-name">
            <span className="first-name">Nima</span>
            <br />
            <span className="last-name">Owji</span>
        </p>
    )
};

export default FullName;