import { useEffect, useState } from "react";

import "./DotGrid.scss";


const DotGrid = ({gridWidth, gridHeight}) => {

    // The array of dots to render
    const [dots, setDots] = useState([]);

    // Renders the dots and udpates the 'dots' state based on the inputs
    useEffect(() => 
    {
        let dotsArray = [];
        
        // Creates the rows
        for (let i = 0; i < gridHeight; i++) {

            let dotsRow = [];

            // Creates the dots in each row
            for (let j = 0; j < gridWidth; j++) {
                dotsRow.push(<div className="dot-item"></div>);
            }

            dotsArray.push(<div className="dots-row">{dotsRow}</div>);
        }
        
        setDots(dotsArray);

    }, [gridWidth, gridHeight]);


    return (
        <div className="dots">
            {dots}
        </div>
    )
};

export default DotGrid;