import NimaImage from "../../images/nima.png";

import "./ProfileImage.scss";


const ProfileImage = () => {
    return (
        <img className="profile-image" src={NimaImage}></img>
    )
};

export default ProfileImage;