import "./SocialLinks.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";


const SocialItem = (props) => {
    return (
        <a href={props.url} target="_blank" className="social-item">
            <FontAwesomeIcon icon={props.icon} className="social-icon"/>
        </a>
    )
};


const SocialLinks = () => {
    return (
        <div className="social-links">
            <SocialItem url="https://x.com/nima_owji" icon={faSquareXTwitter} />
        </div>
    )
};

export default SocialLinks;