import FullName from "./components/FullName/FullName";
import Description from "./components/Description/Description";
import ProfileImage from "./components/ProfileImage/ProfileImage";
import SocialLinks from "./components/SocialLinks/SocialLinks";
import DotGrid from "./components/DotGrid/DotGrid";

import { MouseParallax } from "react-just-parallax";


const App = () => {
  return (
      <div className="hero-container">

        <main className="hero">
          <MouseParallax strength={0.06}>
            <FullName />
          </MouseParallax>

          <MouseParallax strength={0.045}>
            <Description descriptionText={"full-stack web developer, app researcher, blogger"} />
          </MouseParallax>
          <MouseParallax strength={0.045} zIndex={5}>
            <SocialLinks />
          </MouseParallax>

          <MouseParallax isAbsolutelyPositioned={true} zIndex={-5} strength={0.03}>
            <ProfileImage />
          </MouseParallax>

          <MouseParallax isAbsolutelyPositioned={true} strength={0.1}>
            <div className="dot-grid-1">
              <DotGrid gridWidth={8} gridHeight={8} />
            </div>
          </MouseParallax>

          <MouseParallax isAbsolutelyPositioned={true} strength={0.1}>
            <div className="dot-grid-2">
              <DotGrid gridWidth={12} gridHeight={12} />
            </div>
          </MouseParallax>

        </main>

      </div>
  )
};

export default App;